.breadcrumbs {
  margin-top: 90px;
  padding: 16px 0;
  display: flex;
  align-items: center;
  background-color: var(--color-light-6);

  &__content {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -4px;

    > * {
      padding: 0 4px;
    }

    > * + * {
      &::before {
        content: "•";
        display: inline-block;
        margin-right: 8px;
      }

      &:last-child {
        a {
          color: var(--color-dark-1);
        }
      }
    }
  }

  &__item {
    a {
      font-size: 13px;
      line-height: 1;
    }
  }
}
