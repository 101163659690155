.productCard.-type-1 {
  .productCard {
    &__image {
      position: relative;

      img {
        width: 100%;
      }
    }

    &__controls {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      width: 100%;
      height: 100%;
      padding-bottom: 20px;
    }

    &__icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      border-radius: 100%;
      background: white;
      box-shadow: 0px 1px 4px 0px #14034212;
      margin: 0 5px;
      transition: all 0.2s $easeOutCubic;
      opacity: 0;

      .icon {
        color: black;
        width: 15px;
        height: 15px;
      }
    }

    &__content {}

    &__title {}

    &__button {
      a {
        font-size: 15px;
        line-height: 1;
        height: 50px;
        padding: 16px 50px;
      }
    }
  }

  &:hover {
    .productCard {
      &__icon {
        opacity: 1;
      }
    }
  }
}
