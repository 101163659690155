.col:hover{
  color: #ffffff;
}
.col{
  margin-left: -10px;
}
.col1:hover{
background-color: #140342;
color: #ffffff;
border-radius: 25px ;
}

.on-h:hover{
background-color: #140342;
border-radius: 25px;
color: #ffffff;

}

table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
  
}

.tb, th,td{
  border: none;
}

.td1{
  background-color: #dddddd;
}

.back{
  text-align: center;
  background-color: #dddddd;
}
.para{
  margin-top: -20px;
}
.shift{
  display: flex;
}
.none{
  border: none;
}



* {
  box-sizing: border-box;
}

.column {
  float: left;
  width: 33.33%;
  padding: 5px;
cursor: pointer;
}

/* Clearfix (clear floats) */
.row::after {
  content: "";
  clear: both;
  display: table;
}
.column:hover{
  opacity: .7;
}

.card-body{
  position: relative;
  background-color: #ffffff;
  /* margin-top: -50px; */
 /* z-index: 1; */
}


.card {
  background-color: rgb(255, 255, 255);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  max-width: 300px;
  margin: auto;
  text-align: center;
  font-family: arial;
position: absolute;
top: -180px;
right: 10px;  
/* z-index: 1; */
}




.price1:hover{
  background-color: #4f398d;
  border-radius: 30px;
 
}
.price{
  color: #ffffff;
}
.price1 {
  background-color: #53B97E;
  border-radius:8px;
  margin-left: 60px;
  margin-right: 60px;
   padding: 2px;
   margin-top: 5px;
  color: white;
  font-size: 16px;
display: block;
}

.price2 {
  background-color: #53B97E;
  border-radius: 8px;
  margin-left: 60px;
  margin-right: 60px;
   padding: 2px;
  margin-top: -5px;
  color: white;
  font-size: 16px;
display: block;

}
.price2:hover{
  background-color: #4f398d;
  border-radius: 30px;
 
}

.price3 {
  background-color: #53B97E;
  border-radius: 8px;
  margin-left: 60px;
  margin-right: 60px;
   padding: 2px;
  margin-top: -5px;
  color: white;
  font-size: 16px;
display: block;

}

.price3:hover{
  background-color: #4f398d;
  border-radius: 30px;
 
}


.card button {
  border: none;
  outline: 0;
  padding: 12px;
  color: white;
  background-color: #000;
  text-align: center;
  cursor: pointer;
  width: 100%;
  font-size: 18px;
}


/* Media queries */

@media screen and (min-width:200px) and (max-width:600px) {
  .media{
  margin-left: 130px;  
  }
}

@media screen and (min-width:200px) and (max-width:400px) {
  .media .inner{
    position: relative;
    flex: 50%;
    max-width: 60%;
  }
}

@media screen and (min-width:600px) and (max-width:1000px) {
  .media{
  margin-left: 60px;  
  }
}
@media screen and (min-width:1000px) and (max-width:1300px) {
  .media{
  margin-left: 70px;  
  }
}
@media screen and (min-width:220px) and (max-width:720px){
  .media-2{
    display: grid;
    margin-left: 150px;
   
  }    
}

@media screen and (min-width:600px) and (max-width:1000px) {
  .media-2{
    display: flexbox;
    margin-left: 50px;
  }
}
@media screen and (min-width:1000px) and (max-width:1150px) {
  .media-2{
  margin-left: 30px;  
  }
}

@media screen and (min-width:220px) and (max-width:1000px) {
  .media-3{
    max-width: 220px;
  }
}

@media screen and (min-width:200px) and (max-width:460px) {
  .media-3{
    position: relative;
    top:10px;
    max-width: 250px;
    margin-left: 90px;
  }
  .card-body{
   margin-left: 90px;
  }
}

@media screen and (min-width:460px) and (max-width:700px) {
  .media-3{
    position: absolute;
    right:15px;
    top: -400px;
    max-width: 250px;
  }
}
@media screen and (min-width:700px) and (max-width:950px) {
  .media-3{
    position: absolute;
    right:5px;
    top: -250px;
    max-width: 250px;
  }
}

@media screen and (min-width:570px) and (max-width:750px) {
  .media-4 {
    
  position: relative;
  left: 60px;  
  }
}
@media screen and (min-width:200px) and (max-width:570px) {
  .media-4 {
  position: relative;
  
  }
  
}
@media screen and (min-width:200px) and (max-width:1000px) {
  .school-actionsb {
  display: none;
  }
  .hide{
    display: none;
  }
  
}

.row:after {
  content: "";
  display: table;
  clear: both;
}

@media screen and (max-width:550px) {
  .media-5{
    flex: 50%;
    max-width: 70%;    
    margin-left: 150px;
    /* overflow: visible; */
  }
    
}

@media screen and (min-width:200px) and (max-width:400px) {
.media-5 .inner{
overflow-x:auto;
border-collapse: collapse;
  }
}

@media screen and (min-width:200px) and (max-width:400px) {
  .media-5 .content{
    display: flex;
    overflow: scroll;
    margin-right: 0px;

  }
    }

