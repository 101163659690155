body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.sidebar {
  width: 335;
  position: absolute;
  top: 160px;
  right: 10px;
  padding-top: 40px;
}

@media screen and (min-width:200px) and (max-width:700px) {
  .sidebar {
    position: absolute;
    top:300px;
    
  }
  
}

.sidebar div {
  padding: 8px;
  font-size: 24px;
  display: block;
}

.custom-sidebar {
  width: 345px;
  position: absolute;
  top: 160px;
  right: 20px;
  padding-top: 2px;
 
  background-color: #fff;
}

.custom-sidebar div {
  padding: 8px;
  font-size: 24px;
  display: block;
}
.main-content {
  margin-left: -150px;
  font-size: 18px;
}

@media screen and (min-width: 900px) and (max-width:1400px) {
  .custom-sidebar{
    width: 25%;
   margin-right: 10px;
  }
}

@media screen and (min-width: 280px) and (max-width:900px) {
  .custom-sidebar{
   margin-right: 100px;
   width: 40%;
   background-color: #fff;
   
  }
}


@media screen and (min-width: 280px) and (max-width: 900px) {
  .custom-page{
    position: relative;
    top: 380px;
    width: 73.7%;
    overflow-y: visible;
   margin-left: -14px;
  display: block;
  margin-bottom: 500px;
    
  }
}

.custom-page{
background-color: #fff;

}
