.priceCard.-type-1 {
  box-shadow: 0px 20px 30px 0px #19192E0A;

  .priceCard {
    &__list {
      > * {
        display: flex;
        align-items: center;
      }

      > * + * {
        margin-top: 10px;
      }
    }
  }
}
